import React, { useEffect, useState } from 'react'
import Layout from '../components/App/Layout'
import PageBanner from '../components/Common/PageBanner'
import DocumentContent from '../components/Documents/DocumentContent'
import { getDocuments } from '../api/document'
import { navigate } from 'gatsby'

const Document = ({ location }) => {
    const [category, setCategory] = useState(null)
    const [documents, setDocuments] = useState(null)
    const [filters, setFilters] = useState(null)

    const params = new URLSearchParams(location.search)
    const slug = params.get('slug')

    useEffect(() => {
        (async () => {
            try {
                setCategory(null)
                setDocuments(null)
                setFilters(null)
                const data = await getDocuments(slug)
                setCategory(data)
                setDocuments(data.documents)
                setFilters(data.filters)
            } catch (error) {
                setCategory(null)
                setDocuments(null)
                setFilters(null)
                navigate('/404')
            }
        })()
    }, [slug])

    if (!documents) return null

    return (
        <Layout title={category.name}>
            <PageBanner
                pageTitle={category.name}
                homePageText='Inicio'
                homePageUrl='/'
                activePageText={category.name}
            />
            <DocumentContent
                documents={documents}
                filters={filters}
            />
        </Layout>
    )
}

export default Document
