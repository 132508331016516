import React, { useEffect, useState } from 'react'
import DocumentList from './DocumentList'

const DocumentContent = ({ documents, filters }) => {

    const [filter, setFilter] = useState(null)
    const [documentsFilter, setDocumentsFilter] = useState(documents)

    const onInputChange = e => {
        setFilter(e.target.value)
    }

    const filterDocuments = () => {
        if (!filter) return documents
        if (filter === 'all') return documents
        return documents.filter(doc => doc.year.toString() === filter)
    }
   
    useEffect(() => {
        if( filters.length > 0 ){
            setFilter(filters[0].toString());
        }
    }, [])
    useEffect(() => {
        setDocumentsFilter(filterDocuments())
    }, [filter])

    if (!documents) return null

    return (
        <section className='page-area ptb-50'>
            <div className='container'>
                <div className='row mt-3'>
                    <div className='col-12 col-md-9'></div>
                    <div className='col-12 col-md-3'>
                        <select className="form-control" name="filter" id="filter" value={filter} onChange={e => onInputChange(e)}>
                            <option value='all'>Todos los ejercicios</option>
                            {filters.map((filter) => (
                                <option key={filter} value={filter}>Ejercicio {filter}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='row mt-3'>
                    {documentsFilter.map((document) => {
                        return (
                            <DocumentList
                                document={document}
                                key={document.id}
                            />
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default DocumentContent