import React from 'react'
import DocumentCard from './DocumentCard'

const DocumentList = ({ document }) => {
    const { media, name, show_miniature } = document

    return (
        <div className='col-lg-12 mt-3'>
            <div className='document-content'>
                <div className='content'>

                    <h2>{name}</h2>

                    <div className='row'>
                        {media.map((item) => {
                            return (
                                <DocumentCard
                                    key={item.id}
                                    document={item}
                                    show_miniature={show_miniature}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocumentList