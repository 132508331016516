export const getConversion = (media,type='large') => {
    if (media.generated_conversions[type] === true) {
        var parts = media.original_url.split('/');
        parts.pop()
        var new_url=parts.join('/')
        new_url+='/conversions/'+type+'.jpg'
        return new_url;
    } else {
        return media.original_url;
    }
}  